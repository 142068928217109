import React, {useState, useEffect, useCallback} from 'react'
import moment from 'moment'
import Web3 from 'web3'
import AdminService from '../../services/admin.service';
import { contractAbi, contractAddress, rpcUrl } from '../../constants';

const web3 = new Web3(rpcUrl);
const mainContract = new web3.eth.Contract(contractAbi, contractAddress)
const round18 = inp => Math.round(inp/10000000000000000)/100
const round18NoDecimals = inp => Math.round(inp/1000000000000000000).toLocaleString()

function RewardRequests(props) {
  const { setCurrentJob, onFlash} = props
  const [rewRequests, setRewRequests] = useState([]);
  const [earnings, setEarnings] = useState({});
  const [autoConfirm, setAutoConfirm] = useState(false);
  const [dexoUsdRate, setDexoUsdRate] = useState();

  const loadData = useCallback(async () => {
    setCurrentJob('Loading data...')
    const rewardRequest = await AdminService.getRewardRequests()
    setRewRequests(rewardRequest.data)
    getEarnings(rewardRequest.data)
    const settings = await AdminService.getSettings()
    settings.data.map(st=>{
      if (st.settingId === 'conversion_rate') setDexoUsdRate(Number(st.settingValue))
      if (st.settingId === 'auto_confirm_claims') setAutoConfirm(st.settingValue)
    })
    setCurrentJob('')
  },[])

  useEffect(() => {
    loadData()
  }, [loadData])

  async function getEarnings(data) {
    let _earnings = {}
    for (let record of data) {
      if (record.status === 'pending') {
        const userEarnings = await mainContract.methods.earned(record.user).call()
        _earnings[record._id] = userEarnings
      }
    }
    setEarnings(_earnings)
  }

  async function answerRequest(id, user ,action, amount) {
    setCurrentJob('Processing...')
    const reqResult = await AdminService.answerRewardRequests(id, user, action, amount)
    if (reqResult.data.stts === 'done') {
      onFlash({message: 'Transaction was successful', type:'success'})
      setRewRequests(reqResult.data.result)
    } else {
      onFlash({message: 'Transaction failed.', type:'danger'})
    }
    setCurrentJob('')
  }

  const shortenAddress = address => address.slice(0,6) + '....' + address.slice(-4)

  return (
    <div className="admin-settings">
      <div className="white-box mb-2">
        <h4 style={{textAlign: 'center'}}>Withdraw Reward Requests</h4>
        <table className='contact-table mono'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Address</th>
              <th>Earnings ($)</th>
              <th>Earnings (DEXO)</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rewRequests.map((sb,indx)=><tr key={sb+indx}>
              <td>{moment(sb.createdAt).format('MMM DD HH:mm')}</td>
              <td>{shortenAddress(sb.user)}</td>
              {sb.status === 'approved' ? <React.Fragment>
                <td>{sb.amount ? round18(Number(sb.amount)) : '-' }</td>
                <td>{sb.amount && dexoUsdRate ? round18NoDecimals(Number(sb.amount)/dexoUsdRate) : '-' }</td>
              </React.Fragment> :
              <React.Fragment>
                <td>{earnings[sb._id] ? round18(earnings[sb._id]) : '-'}</td>
                <td>{earnings[sb._id] && dexoUsdRate ? round18NoDecimals(earnings[sb._id]/dexoUsdRate) : '-' }</td>
              </React.Fragment>}
              <td>{sb.status}</td>
              <td>
                {sb.status === 'pending' && !autoConfirm ?
                <React.Fragment>
                  <button className='sbtn sbtn-success mr-1' onClick={()=>answerRequest(sb._id, sb.user, 'accept', earnings[sb._id])}>
                    <i className='fa-light fa-check'></i>
                  </button>
                  <button className='sbtn sbtn-danger' onClick={()=>answerRequest(sb._id, '', 'reject')}>
                    <i className='fa-light fa-trash'></i>
                  </button>
                </React.Fragment> : null}
              </td>
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RewardRequests;