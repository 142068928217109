import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactTabulator } from 'react-tabulator'
import { contractAddress, dexoContract, masterWallet, networkId } from "../constants";
import StakingService from '../services/staking.service'
import 'tabulator-tables/dist/css/tabulator.min.css'

const columns = [
  { title: "Date", field:"timeStamp", formatter:function(cell, formatterParams, onRendered){
    const date = new Date(Number(cell.getValue())*1000);
    return date.toLocaleString()
  }},
  { title: "From", field: "from" },
  { title: "To", field: "to" },
  { title: "Amount", field: "value", width: 150, formatter:function(cell, formatterParams, onRendered){
    return Math.round(Number(cell.getValue())/100000000000000)/10000
  }}
];

function Transactions(props) {
  const navigate = useNavigate();
  const { moralisObj, setCurrentJob} = props
  const {
    isWeb3Enabled,
    isAuthenticated,
    user,
  } = moralisObj

  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [userStakes, setUserStakes] = useState([])
  const [activeTab, setActiveTab] = useState(0)

  const [earnings, setEarnings] = useState({
    packages: 0,
    referrals: 0,
    staking: 0,
    total: 0
  })

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob('Loading transactions...')
      checkTransactions()
    }
    if(!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated, navigate, user ]);

  async function checkTransactions() {
    try {
      const userAddress = user.attributes.ethAddress.toLowerCase()
      const fp = await fetch(`https://${networkId === 56 ? 'api' : 'api-testnet'}.bscscan.com/api?module=account&action=tokentx&address=${userAddress}&apikey=1EKFXBIPDVD95U3ATK32UD75W7U3ZNPY1J`)
      const trans = await fp.json()
      if (trans.result) {
        const _stakes = await StakingService.userStakes(userAddress);
        const _unstaked = _stakes.data.filter(stk=> stk.status === 'unstaked')
        setUserStakes(_unstaked.map(stk=>stk.unstake_hash))
        const result = trans.result.filter(rs=> rs.contractAddress === dexoContract)

        const _earningsFromPackages = result.filter(td=> td.from === contractAddress.toLowerCase()  && td.to === userAddress)
        .reduce(function (accumulator, curValue) {return accumulator + Number(curValue.value)}, 0)

        const _earningsFromReferral = result.filter(td=> td.from === masterWallet && td.to === userAddress && !_unstaked.includes(td.hash))
        .reduce(function (accumulator, curValue) {return accumulator + Number(curValue.value)}, 0)

        const _earningsFromStaking = _unstaked.reduce(function (accumulator, curValue) {return accumulator + Number(curValue.return_amount) - Number(curValue.amount)}, 0)

        setEarnings({
          packages: _earningsFromPackages/1000000000000000000,
          referrals: _earningsFromReferral/1000000000000000000,
          staking: _earningsFromStaking/1000000000000000000,
          total: (_earningsFromPackages + _earningsFromReferral + _earningsFromStaking)/1000000000000000000
        })
        setTableData(result)
        setFilteredData(result)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
    }
  }
  function changeTab(tab) {
    setActiveTab(tab)
    let newData = tableData
    const userAddress = user.attributes.ethAddress.toLowerCase()
    if (tab === 0) newData = tableData
    if (tab === 1) newData = tableData.filter(td=> td.from === contractAddress.toLowerCase()  && td.to === userAddress)
    if (tab === 2) newData = tableData.filter(td=> td.from === masterWallet                   && td.to === userAddress    && !userStakes.includes(td.hash))
    if (tab === 3) newData = tableData.filter(td=> td.from === masterWallet                   && td.to === userAddress    && userStakes.includes(td.hash))
    if (tab === 4) newData = tableData.filter(td=> td.from !== contractAddress.toLowerCase()  && td.from !== masterWallet && td.to === userAddress)
    if (tab === 5) newData = tableData.filter(td=> td.from === userAddress                    && td.to !== userAddress)
    setFilteredData(newData)
  }

  return <div className="container">
    <div style={{overflow: 'hidden'}}>
      <div className='dexo-cards-container'>
        <h3>DEXO Earnings</h3>
        <div className='dexo-cards'>
          <div className='dc-header'>
            <i className='fa-light fa-wallet'></i>
            <h4>Total</h4>
          </div>
          <div className='dc-value'>
            <div>{earnings.total.toLocaleString()}</div> DEXO
          </div>
        </div>
        <div className='dexo-cards'>
          <div className='dc-header'>
            <i className='fa-light fa-boxes'></i>
            <h4>From Packages</h4>
          </div>
          <div className='dc-value'>
            <div>{earnings.packages.toLocaleString()}</div> DEXO
          </div>
        </div>
        <div className='dexo-cards'>
          <div className='dc-header'>
            <i className='fa-light fa-users'></i>
            <h4>From Referrals</h4>
          </div>
          <div className='dc-value'>
            <div>{earnings.referrals.toLocaleString()}</div> DEXO
          </div>
        </div>
        <div className='dexo-cards'>
          <div className='dc-header'>
            <i className='fa-light fa-coins'></i>
            <h4>From Staking</h4>
          </div>
          <div className='dc-value'>
            <div>{earnings.staking.toLocaleString()}</div> DEXO
          </div>
        </div>
      </div>
    </div>
    <div className='white-box'>
      <h3>DEXO Transactions</h3>
      <div className="admin-tabs">
        <div onClick={()=>{changeTab(0)}} className={`admin-tab ${activeTab === 0 ? 'active' : ''}`}>All</div>
        <div onClick={()=>{changeTab(1)}} className={`admin-tab ${activeTab === 1 ? 'active' : ''}`}>From Packages</div>
        <div onClick={()=>{changeTab(2)}} className={`admin-tab ${activeTab === 2 ? 'active' : ''}`}>From Referrals</div>
        <div onClick={()=>{changeTab(3)}} className={`admin-tab ${activeTab === 3 ? 'active' : ''}`}>From Staking</div>
        <div onClick={()=>{changeTab(4)}} className={`admin-tab ${activeTab === 4 ? 'active' : ''}`}>Other Sources</div>
        <div onClick={()=>{changeTab(5)}} className={`admin-tab ${activeTab === 5 ? 'active' : ''}`}>Spent</div>
      </div>
      <ReactTabulator
        data={filteredData}
        columns={columns}
        initialSort={[{column:"timeStamp", dir:"desc"}]}
        layout={"fitColumns"}
      />
    </div>
  </div>
}

export default Transactions;