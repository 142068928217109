import React, {useRef} from "react";
import { dexoContract, oneXdContract, oneSdContract, blockExplorerUrl } from "../constants";
import "./help.scss";

function Help() {

  const introRef = useRef(null)
  const addTokenRef = useRef(null)
  const addMetaRef = useRef(null)
  const addTrustRef = useRef(null)

  function scrollTo(dRef){
    dRef.current.scrollIntoView()
  }

  async function addToken(tknId) {
    const options = {
      address: tknId === 'dexo' ? dexoContract : tknId === '1sd' ? oneSdContract : oneXdContract,
      symbol: tknId === 'dexo' ? 'DEXO' : tknId === '1sd' ? '1SD' : '1XD',
      decimals: 18
    }
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options
        },
      });
      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss');
      }
    } catch (error) {
      console.log(error);
    }
  }

  return <div className="container help-section">
    <div className="white-box mb-3">
      <div className="table-of-content">
        <h4>Table of Contents</h4>
        <hr />
        <ul>
          <li onClick={()=>scrollTo(introRef)}>Introduction</li>
          <li onClick={()=>scrollTo(addTokenRef)}>Add Tokens To Your Wallet</li>
          <ul>
              <li onClick={()=>scrollTo(addMetaRef)}>MetaMask</li>
              <li onClick={()=>scrollTo(addTrustRef)}>TrustWallet</li>
            </ul>
        </ul>
      </div>
        <div ref={introRef}>
          <h2>Introduction</h2>
          <p>Welcome to DexFee!</p>
        </div>
        <hr />
        <div ref={addTokenRef}>
          <h2>Add Tokens To Your Wallet</h2>
          <p>DexFee uses 2 proprietary tokens.</p>
          <b>DEXO (DexFee Platfom Optimization)</b>
          <p>for staking purposes and paying out investment interests. you can exchange this token like any other standard token.</p>
          <b>1XD (Decentralized Democratic Token)</b>
          <p>1XD is a stable coin. At the time of activating a package, investors will receive 1XD equal to their investment.</p>
          <p>In order to keep track of your assets in DexFee, we recommend to add them to your wallet.</p>
        </div>
        <hr />
        <div ref={addMetaRef}>
          <h2>MetaMask</h2>
          <button className="sbtn sbtn-secondary" onClick={()=>{addToken('dexo')}}>Add DEXO</button>
          <button className="sbtn sbtn-secondary" onClick={()=>{addToken('1xd')}}>Add 1XD</button>
          <button className="sbtn sbtn-secondary" onClick={()=>{addToken('1sd')}}>Add 1SD</button>
        </div>
        <div ref={addTrustRef}>
          <h2>TrustWallet</h2>
          <div className="qr-codes">
            <a href={blockExplorerUrl + "/address/" + dexoContract} target="_blank" className="qrcode-wrapper">
              <img src="/qr_dexo.png?2" alt="dexo qr code" />
              <strong>
                <i className="fa-light fa-link"></i>
                DEXO
              </strong>
            </a>
            <a href={blockExplorerUrl + "/address/" + oneXdContract} target="_blank" className="qrcode-wrapper">
              <img src="/qr_onexd.png?2" alt="1xd qr code" />
              <strong>
                <i className="fa-light fa-link"></i>
                1XD
              </strong>
            </a>
            <a href={blockExplorerUrl + "/address/" + oneSdContract} target="_blank" className="qrcode-wrapper">
              <img src="/qr_onesd.png?4" alt="1sd qr code" />
              <strong>
                <i className="fa-light fa-link"></i>
                1SD
              </strong>
            </a>
          </div>
        </div>
    </div>
  </div>
}

export default Help;