import React from "react";

const usdtMinAllowance = 400000000000000000000000
const onesdMinAllowance = 400000000000000000000000
const dexoMinAllowance = 80000000000000000000000000000

function AllowanceLink({allowance, activeCurrency, onApproveToken}) {
  let result = null
  if (allowance.usdt < usdtMinAllowance && activeCurrency === 'usdt')
    result = <div className="pkg-text" onClick={()=>onApproveToken('usdt')}>Activating this package requires spend allowance.
        <span className="s-link">(click here to allow)</span>
      </div>

  if (allowance.dexo < dexoMinAllowance && activeCurrency === 'dexo')
    result = <div className="pkg-text" onClick={()=>onApproveToken('dexo')}>Activating this package requires spend allowance.
        <span className="s-link">(click here to allow)</span>
      </div>

  if (allowance.onesd < onesdMinAllowance && activeCurrency === '1sd')
    result = <div className="pkg-text" onClick={()=>onApproveToken('1sd')}>Activating this package requires spend allowance.
      <span className="s-link">(click here to allow)</span>
    </div>
  return result
}

function PackageButton ({stakePending, onPackageSelect, activeCurrency, allowance, userTokens, price}) {
  let result = null
  const allowanceProblem = (activeCurrency === 'usdt' && allowance.usdt < usdtMinAllowance) || (activeCurrency === '1sd' && allowance.onesd < onesdMinAllowance) || (activeCurrency === 'dexo' && allowance.dexo < dexoMinAllowance)
  if (stakePending) {
    result = <button className="sbtn sbtn-disabled">Processing...</button>
  } else if (userTokens[activeCurrency] < price * 1000000000000000000) {
    result = <button className="sbtn sbtn-disabled">Insufficient Funds</button>
  } else if (allowanceProblem) {
    result = <button className="sbtn sbtn-disabled">Activate</button>
  } else {
    result = <button className="sbtn sbtn-primary" onClick={() => onPackageSelect()}>Activate</button>
  }
  return result
}

function Package(props) {
  const {title, price, nominalPrice, onPackageSelect, stakePending, allowance, onApproveToken, description1, description2, activeCurrency, userTokens/*, devMode, onDemoPackageSelect*/} = props
  return (
    <div className="pkg">
      <div className="pkg-header">
        <div className="pkg-title">{title} <strong style={{color: "#ffe802"}}>(${nominalPrice.toLocaleString()})</strong></div>
        <div className="pkg-price">{price.toLocaleString()} {activeCurrency.toUpperCase()}</div>
        <div className="pkg-note">{description1}</div>
        <div className="pkg-note">{description2}</div>
      </div>
      <AllowanceLink allowance={allowance} activeCurrency={activeCurrency} onApproveToken={(token)=>onApproveToken(token)}/>
      <div className="pkg-button">
        <PackageButton
          stakePending={stakePending}
          allowance={allowance}
          activeCurrency={activeCurrency}
          onPackageSelect={onPackageSelect}
          price={price}
          userTokens={userTokens}
        />
        {/*devMode ? <button style={{marginTop: 10}} className="sbtn sbtn-primary" onClick={() => onDemoPackageSelect()}>Activate (Demo)</button> : null */}
      </div>
    </div>
  );
}

export default Package;