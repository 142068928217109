import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import { usdtContract, chainName, contractAbi, contractAddress, dexoAbi, dexoContract, masterWallet, oneXdContract, oneSdContract, rpcUrl, networkId } from "../../constants";
import "./dashboard.scss";
import AdminService from "../../services/admin.service";
import UsersService from "../../services/users.service";
import PackagesService from "../../services/packages.service";

const web3 = new Web3(rpcUrl);
const mainContract = new web3.eth.Contract(contractAbi, contractAddress)
const dexoContractObject = new web3.eth.Contract(dexoAbi, dexoContract)

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function Dashboard(props) {
  const { moralisObj, setCurrentJob, onFlash} = props
  const {
    isWeb3Enabled,
    isAuthenticated,
    user,
    enableWeb3,
    Moralis,
    
  } = moralisObj

  const round18 = inp => Math.round(inp/10000000000000000)/100

  const [checking, setChecking] = useState(false);
  const [earnings, setEarnings] = useState(-2)
  //const [minDexoClaimAmount, setMinDexoClaimAmount] = useState(-2)
  const [claimPending, setClaimPending] = useState(false)
  const [hasActiveClaim, setHasActiveClaim] = useState(false)
  const [rwdDetail, setRwdDetail] = useState({
    fromPackages: 0,
    fromReferrals: 0,
    fromOthers: 0,
    spendings: 0
  })

  const [tokens, setTokens] = useState({usdt: 0, dexo: 0, onesd: 0, onexd: 0})
  const navigate = useNavigate()

  const [dexoUsdRate, setDexoUsdRate] = useState(0)
  const [packagesTotal, setPackagesTotal] = useState(0)
  const [stakesTotal, setStakesTotal] = useState(0)
  const [systemTotalStakings, setSystemTotalStakings] = useState(0)
  const [circulatingSupply, setCirculatingSupply] = useState(0)
  
  const [showClaimMessage, setShowClaimMessage] = useState(false)

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob('Loading packages info...')
      if (!checking) checkInitialValues()
      setChecking(true)
    } else {
      enableWeb3()
    }
    if(!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated, enableWeb3, navigate, user])

  useInterval(()=>{
    mainContract.methods.earnedLive(user.attributes.ethAddress).call().then((userEarnings) => {
      setEarnings(userEarnings/86400)
    }).catch((err)=>{
      console.log(err)
    })
  },5000)

  async function checkInitialValues() {
    //const contract = new web3.eth.Contract(erc20AbiJson, tokenAddress);
    //const tokenBalance = await contract.methods.balanceOf(myAddress).call();
    try {
      const [dashData, _tokens, _hasActiveClaim, _dexoInMasterWallet, _dexoInMainContract] = await Promise.all([
        getDashboardData(),
        Moralis.Web3API.account.getTokenBalances({ chain: chainName}),
        PackagesService.getClaimStatus(user.attributes.ethAddress),
        dexoContractObject.methods.balanceOf(masterWallet).call(),
        dexoContractObject.methods.balanceOf(contractAddress).call(),
        checkTransactions(),
      ]);
      const totalSupply = 800000000000
      
      let _circulation = (totalSupply - (_dexoInMasterWallet/1000000000000000000 + _dexoInMainContract/1000000000000000000))
      setDexoUsdRate(dashData.data.dexoUsdRate || 0)
      setPackagesTotal(dashData.data.packagesTotal || 0)
      setStakesTotal(dashData.data.stakesTotal || 0)
      setSystemTotalStakings(dashData.data.systemStakesTotal || 0)
      setCirculatingSupply(Math.round(_circulation + Number(dashData.data.systemStakesTotal)))
      
      setHasActiveClaim(_hasActiveClaim.data.hasActiveClaim)
      updateTokens(_tokens)
      //mainContract.methods.minAmountToGetReward().call().then((minDexo) => {
      //  setMinDexoClaimAmount(parseInt(minDexo))
      //})
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
      setChecking(false)
    }
  }

  async function getDashboardData () {
    const dashData = await UsersService.getDashboardData(user.attributes.sessionToken)
    return dashData
  }

  function updateTokens(_tokens) {
    let tempTokens = {usdt: 0, dexo: 0, onesd: 0, onexd: 0}
    _tokens.map(tkn => {
      if (tkn.token_address === usdtContract.toLowerCase()) tempTokens.usdt = round18(tkn.balance)
      if (tkn.token_address === oneXdContract.toLowerCase()) tempTokens.onexd = round18(tkn.balance)
      if (tkn.token_address === oneSdContract.toLowerCase()) tempTokens.onesd = round18(tkn.balance)
      if (tkn.token_address === dexoContract.toLowerCase()) tempTokens.dexo = tkn.balance
      return null
    })
    setTokens({...tempTokens})
  }
  async function checkTransactions() {
    const userAddress = user.attributes.ethAddress
    const fp = await fetch(`https://${networkId === 56 ? 'api' : 'api-testnet'}.bscscan.com/api?module=account&action=tokentx&address=${userAddress}&apikey=1EKFXBIPDVD95U3ATK32UD75W7U3ZNPY1J`)
    const trans = await fp.json()
    const result = trans.result.filter(rs=> rs.contractAddress === dexoContract && rs.to === userAddress)
    const spendings = trans.result.filter(rs=> rs.contractAddress === dexoContract && rs.from === userAddress).reduce((a, b) => +a + +b.value, 0)
    let fromPackages = 0
    let fromReferrals = 0
    let fromOthers = 0
    result.map(rs=> {
      const trFrom = rs.from.toLowerCase()
      const trAmount = Number(rs.value)
      if (trFrom === usdtContract) {
        fromPackages += trAmount
      } else if (trFrom === masterWallet) {
        fromReferrals += trAmount
      } else {
        fromOthers += trAmount
      }
      return null
    })
    setRwdDetail({fromPackages, fromReferrals, fromOthers, spendings})
    return result
  }
  async function claimRewards() {
    setClaimPending(true)
    const tempEarnings = earnings
    setEarnings(-2)
    const _userRealEarnings = await mainContract.methods.earned(user.attributes.ethAddress).call()
    if (_userRealEarnings < 10000000000000000000) {
      setShowClaimMessage(true)
      setEarnings(tempEarnings)
      setClaimPending(false)
    } else {
      try {
        const result = await PackagesService.requestRewards(user.attributes.sessionToken)
        if (result.data === 'done') {
          setHasActiveClaim(true)
          onFlash({message: 'Your request has been submitted.', type:'success'})
        } else {
          onFlash({message: 'Failed to submit request', type:'danger'})
          AdminService.log(user.attributes.ethAddress, 'claim failed 1', JSON.stringify(result))
          setEarnings(tempEarnings)
          setClaimPending(false)
        }
      } catch (error) {
        console.log(error)
        onFlash({message: 'Failed to submit request', type:'danger'})
        AdminService.log(user.attributes.ethAddress, 'claim failed 2', JSON.stringify(error))
        setEarnings(tempEarnings)
        setClaimPending(false)
      }
    }
  }

  return (
    <div className="container">
      <div className="white-box mb-2">
        <h3>Your Investments</h3>
        <div className="tokens-box">
          <div className="tkb">
            <h5>Packages <span style={{display: "block", fontWeight: 400, fontSize: "15px"}}>(APY: 48%)</span></h5>
            <b>{packagesTotal.toLocaleString()} USD</b>
          </div>
          <div className="tkb">
            <h5>Stakings <span style={{display: "block", fontWeight: 400, fontSize: "15px"}}>(BPY: 960%)</span></h5>
            <b>{stakesTotal.toLocaleString()} DEXO</b>
          </div>
          <div className="tkb">
            {hasActiveClaim ?
            <div>
              <h3>Realtime Rewards</h3>
              <div>
                We have received your request and will process it soon.
              </div>
            </div> : showClaimMessage ? 
            <div>
              <h3>Realtime Rewards</h3>
              <div style={{color: "#ffe800"}}>Please try half a day later, and you will definitely receive it next time. Right now, your reward is a very tiny amount lower than the minimum required.</div>
            </div> :
            <div id="tour_claim_rewards">
              <div className="rewards">
                <h3>Realtime Rewards</h3>
                {earnings === -2 ?
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                <React.Fragment>
                  <b>{round18(earnings/dexoUsdRate).toLocaleString()} DEXO</b>
                  <div className="reward-note">Min. Claim Amount: {(10/dexoUsdRate).toLocaleString()} DEXO</div>
                  {earnings >= 10000000000000000000 ?
                    claimPending ? <button className="sbtn sbtn-disabled">Processing...</button> :
                    <button className="sbtn sbtn-primary" onClick={claimRewards}>Claim Rewards</button> :
                    <button className="sbtn sbtn-disabled">Claim Rewards</button> }
                </React.Fragment>}
              </div> 
            </div> }
          </div>
        </div>
      </div>
      <div className="white-box mb-2">
        <h3>Dexo Overview</h3>
        <div className="tokens-box">
          <div className="tkb">
            <h5>Dexo Total Supply</h5>
            <b>800,000,000,000</b>
          </div>
          <div className="tkb">
            <h5>Circulating Supply</h5>
            <b>{circulatingSupply.toLocaleString()} DEXO</b>
          </div>
          <div className="tkb">
            <h5>Active Stakings</h5>
            <b>{systemTotalStakings.toLocaleString()} DEXO</b>
          </div>
        </div>
      </div>
      <div className="white-box mb-2" id="tour_assets">
        <h3>Your Wallet</h3>
        <div className="tokens-box user-wallet">
          <div className="tkb">
            <h5>USDT</h5>
            <div className="tkb-fullname">Tether</div>
            <b>{tokens.usdt.toLocaleString()}</b>
          </div>
          <div className="tkb">
            <h5>1SD</h5>
            <div className="tkb-fullname">One Shiny Dexo</div>
            <b>{tokens.onesd.toLocaleString()}</b>
          </div>
          <div className="tkb">
            <h5>1XD</h5>
            <div className="tkb-fullname">Decentralized Democratic Token</div>
            <b>{tokens.onexd.toLocaleString()}</b>
          </div>
          <div className="tkb flip-container" >
            <div className="flipper">
              <div className="front">
                <h5>DEXO</h5>
                <div className="tkb-fullname">DexFee Platfom Optimization</div>
                <b>{round18(tokens.dexo).toLocaleString()}</b>
                <div className="tkb-hover">(Hover for more details)</div>
              </div>
              <div className="back">
                <div className="token-source">
                  <div className="ts-row tsr-green"><span>From Packages:</span> +{round18(rwdDetail.fromPackages)} DEXO</div>
                  <div className="ts-row tsr-green"><span>From Referrals:</span> +{round18(rwdDetail.fromReferrals)} DEXO</div>
                  <div className="ts-row tsr-green"><span>Others:</span> +{round18(rwdDetail.fromOthers)} DEXO</div>
                  <div className="ts-row tsr-red"><span>Spent:</span> -{round18(rwdDetail.spendings)} DEXO</div>
                  <div><span>Conversion Rate:</span> ${dexoUsdRate}</div>
                  <div className="ts-row tsr-white"><span>Approx Value:</span> ${round18(tokens.dexo * dexoUsdRate)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;