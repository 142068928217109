import React, {useState, useEffect} from 'react'
import usersService from '../../services/users.service'
import './contact_us.scss'

function ContactUs(props) {
  const {moralisObj, setCurrentJob, onFlash} = props
  const {user} = moralisObj

  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('Packages')
  const [desc, setDesc] = useState('')

  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState(false)

  async function sendContactForm() {
    setInvalidEmail(!isEmail(email))
    setInvalidMessage(!desc)
    if (Boolean(desc) && isEmail(email)) {
      setCurrentJob('Loading data...')
      try {
        const res = await usersService.contact(user.attributes.sessionToken, email,subject,desc)
        if (res.data === 'done') {
          onFlash({message: 'Your message has been submitted.', type:'success'})
          setEmail('')
          setSubject('')
          setDesc('')
        } else if (res.data === 'tooMany') {
          onFlash({message: 'Too many requests!', type:'danger'})
        } else {
          onFlash({message: 'There was an error!', type:'danger'})
        }
      } catch (err) {
        console.log(err)
      } finally {
        setCurrentJob('')
      }

    }
  }

  function isEmail(inputValue) {
    return (/^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(inputValue);
  }
  

  return <div className="container contact-us" >
    <div className="admin-fn-box white-box">
      <h4>Contact Us</h4>
      <p>We're here to help and answer any question you might have. We look forward to hearing from you.</p>
      <div className="afb-body">
        <div className={`afb-input ${invalidEmail ? 'invalid' : ''}`}>
          <label>Your Email</label>
          <input value={email} className="cs-textinput" onChange={e=>setEmail(e.target.value)}/>
          <span className='validation-text'>Please enter a valid email</span>
        </div>
        <div className="afb-input">
          <label>Subject</label>
          <select className='form-select' onChange={e=>setSubject(e.target.value)}>
            <option>Packages</option>
            <option>Referral</option>
            <option>Wallets</option>
            <option>Transactions</option>
            <option>Staking</option>
            <option>Marshall</option>
            <option>Other</option>
          </select>
        </div>
        <div className={`afb-input ${invalidMessage ? 'invalid' : ''}`}>
          <label>Message</label>
          <textarea className="cs-textinput" onChange={e=>setDesc(e.target.value)} value={desc} rows={5}></textarea>
          <span className='validation-text'>Message can't be empty</span>
        </div>
        <button className="sbtn sbtn-primary" onClick={()=>sendContactForm()}>Send</button>
      </div>
    </div>
  </div>
}

export default ContactUs;