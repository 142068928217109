import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { oneXdAbi, oneXdContract, oneSdAbi, oneSdContract, usdtAbi, usdtContract, contractAbi, contractAddress, dexoContract, dexoAbi, snimda, chainName } from "../../constants";
import Package from "../../components/Package";
import VSwitch from "../../components/vswitch/VSwitch";
import RadioButton from "../../components/RadioButton/RadioButton";
import StakingService from "../../services/staking.service";
import PackagesService from "../../services/packages.service";
import AdminService from "../../services/admin.service";
import "./packages.scss"

const round18 = inp => (Math.round(inp/10000000000000000)/100).toLocaleString()

function Packages(props) {
  const {referral, moralisObj, setCurrentJob, onFlash} = props

  const { isWeb3Enabled, isAuthenticated, user, Moralis } = moralisObj
  const navigate = useNavigate();
  const [packagesStatus, setPackagesStatus] = useState([
    {id: 0, pending: false},
    {id: 1, pending: false},
    {id: 2, pending: false},
    {id: 3, pending: false}
  ]);
  const [checking, setChecking] = useState(false);
  const [allowance, setAllowance] = useState({usdt: 0, onexd: 0, dexo: 0});
  const [activeCurrency, setActiveCurrency] = useState('dexo');
  const [dexoUsdRate, setDexoUsdRate] = useState(0.0000025);
  const [userTokens, setUserTokens] = useState({usdt: 0, dexo: 0})
  const [packagesHistory, setPackagesHistory] = useState([])
  const [minUsdToStakeDexo, setMinUsdToStakeDexo] = useState(0)
  const [totalInvestment, setTotalInvestment] = useState(0)
  //const [devMode, setDevMode] = useState(false)

  const [dynamicPackages, setDynamicPackages] = useState([])

  useEffect(() => {
    if (isAuthenticated && isWeb3Enabled) {
      setCurrentJob('Loading packages info...')
      if (!checking) checkPackagesStatus()
      setChecking(true)
    }
    if(!user) navigate(`/${window.location.search}`, { replace: true });
  }, [isWeb3Enabled, isAuthenticated]);

  async function checkPackagesStatus() {
    try {
      await Promise.all([
        loadInvestments(),
        checkAllowance(),
        loadSettings(),
        loadUserTokens(),
        /* getMinUsdToStakeDexo(), */
        getPackagesData()
      ]);
    } catch (err) {
      console.log(err)
    } finally {
      setCurrentJob('')
      setChecking(false)
    }
  }
  async function reloadData() {
    await Promise.all([
      loadInvestments(),
      checkAllowance(),
      loadUserTokens()
    ]);
  }
  async function getPackagesData() {
    const pkgs = await PackagesService.getAllPackages()
    const pkgsWithSimpleId = pkgs.data.map((pkg,indx)=>{pkg.id = indx; return pkg})
    setDynamicPackages(pkgsWithSimpleId)
  }
  function getLastInArray(arr){
    let response = arr
    if (Array.isArray(arr)) {
      response = arr.reverse()[0]
    }
    return response
  }
  async function loadSettings() {
    setCurrentJob('Loading data...')
    const settings = await AdminService.getSettings()
    settings.data.map(st=>{
      if (st.settingId === 'conversion_rate_hidden') setDexoUsdRate(st.settingValue)
    })
    setCurrentJob('')
  }

  async function loadInvestments () {
    const fn = {
      contractAddress,
      functionName: "getUserInfo",
      abi: contractAbi,
      params: {
        user: user.attributes.ethAddress
      },
    };
    const _userInfo = await Moralis.executeFunction(fn)
    let tempPackages = []
    let _ttlInv = 0
    _userInfo.map(pkg => {
      const stakeTime = parseInt(pkg.stakeTime._hex)
      if (stakeTime > 0) {
        tempPackages.push({
          date: stakeTime,
          amount: parseInt(pkg.amount._hex),
          isActive: pkg.isActive,
          tokenId: parseInt(pkg.tokenId._hex),
          dexoAmount: parseInt(pkg.dexoAmount ? pkg.dexoAmount._hex : 0)
        })
        _ttlInv += parseInt(pkg.amount._hex)
      }
      return pkg
    })
    tempPackages.reverse()
    setTotalInvestment(_ttlInv)
    setPackagesHistory([...tempPackages])
  }

  async function getMinUsdToStakeDexo () {
    const fn = {
      contractAddress,
      functionName: "minAmountToStakeWithDexo",
      abi: contractAbi
    };
    const res = await Moralis.executeFunction(fn)
    setMinUsdToStakeDexo(parseInt(res._hex))
  } 

  async function loadUserTokens() {
    const _tokens = await Moralis.Web3API.account.getTokenBalances({ chain: chainName})
    let tempTokens = {usdt: 0, dexo: 0}
    _tokens.map(tkn => {
      if (tkn.token_address === usdtContract.toLowerCase()) tempTokens.usdt = tkn.balance
      if (tkn.token_address === dexoContract.toLowerCase()) tempTokens.dexo = tkn.balance
      return null
    })
    setUserTokens({...tempTokens})
  }

/*   async function betterPackageActivate (amount, pkgId) {
    //const theContract = new web3js.eth.Contract(contractAbi, contractAddress);
    const web3 = new Web3(window.ethereum);
    const mainContract = new web3.eth.Contract(contractAbi, contractAddress)
    const _tokenId = activeCurrency === 'usdt' ? 0 : activeCurrency === 'dexo' ? 1 : 2
    const res = await mainContract.methods.stake(amount, user.attributes.ethAddress, _tokenId).send({ 
      from: user.attributes.ethAddress,
      to: contractAddress,
      gas: 2000000,
    })
    console.log(res)
  }
 */
  async function selectPackage(amount, pkgId) {
    const fn = {
      contractAddress,
      functionName: "stake",
      abi: contractAbi,
      params: {
        _address: user.attributes.ethAddress,
        amount,
        tokenId: activeCurrency === 'dexo' ? 1 : 2
      },
    };
    let tempPkgSt = packagesStatus.map(pkg => {
      pkg.pending = pkgId === pkg.id ? true : pkg.pending
      return pkg
    })
    setPackagesStatus(tempPkgSt)
    let preActId
    try {
      const preAct = await StakingService.preActivatePackage(user.attributes.sessionToken, referral, activeCurrency, amount)
      if (preAct.data === 'error') {
        onFlash({message: 'There was an error. please contact admin', type:'danger'})
        throw 'Invalid data'
      }
      preActId = preAct.data
      const res = await Moralis.executeFunction(fn)
      await Promise.all([
        StakingService.activatePackage(res.hash, user.attributes.sessionToken, referral, user.attributes.ethAddress, activeCurrency, amount, preActId),
        res.wait()
      ])
      tempPkgSt = packagesStatus.map(pkg => {
        if(pkgId === pkg.id) {
          pkg.pending = pkgId === pkg.id ? false : pkg.pending
        }
        return pkg
      })
    } catch (err) {
      if (err.code === 4001){
        await StakingService.removePreActivation(user.attributes.sessionToken, preActId)
      }
      console.log(err)
    } finally {
      tempPkgSt = packagesStatus.map(pkg => {
        pkg.pending = pkgId === pkg.id ? false : pkg.pending
        return pkg
      })
      reloadData()
      setPackagesStatus(tempPkgSt)
    }
  }
/*
  async function selectDemoPackage(pkgId) {
    setCurrentJob("Rewarding referrals...")
    if (user.attributes.ethAddress !== referral) {
      await Moralis.Cloud.run("rewardRefs", {user: user.attributes.ethAddress, ref: referral, pkgId});
      onFlash({message: 'Referrals have been rewarded', type:'success'})
    }
    setCurrentJob("")
  }
*/

  async function checkAllowance() {
    const sharedParams = {
      functionName: "allowance",
      params: {owner: user.attributes.ethAddress,spender: contractAddress}
    }
    const fnUsdt = {...sharedParams,contractAddress: usdtContract,abi: usdtAbi}
    const fnDexo = {...sharedParams,contractAddress: dexoContract,abi: dexoAbi}
    const fnOneSd = {...sharedParams,contractAddress: oneSdContract,abi: oneSdAbi}
    const fnOneXd = {...sharedParams,contractAddress: oneXdContract,abi: oneXdAbi}
    try {
      const [_usdtAllowance,_dexoAllowance,_onesdAllowance,_oneXdAllowance] = await Promise.all([
        Moralis.executeFunction(fnUsdt),
        Moralis.executeFunction(fnDexo),
        Moralis.executeFunction(fnOneSd),
        Moralis.executeFunction(fnOneXd)
      ])
      setAllowance({
        usdt: parseInt(_usdtAllowance._hex),
        dexo: parseInt(_dexoAllowance._hex),
        onesd: parseInt(_onesdAllowance._hex),
        onexd: parseInt(_oneXdAllowance._hex)
      })
    } catch(err) {
      console.log(err)
    }
  }
  async function approveToken(token) {
    const amount = (token === 'usdt' || token === 'onexd' || token === '1sd') ? "500000000000000000000000" : "99000000000000000000000000000"
    const sharedParams = {
      functionName: "approve",
      params: {
        spender: contractAddress,
        amount
      }
    }
    let fn
    if (token === "usdt") fn = {...sharedParams,contractAddress: usdtContract,abi: usdtAbi}
    if (token === "dexo") fn = {...sharedParams,contractAddress: dexoContract,abi: dexoAbi}
    if (token === "onexd") fn = {...sharedParams,contractAddress: oneXdContract,abi: oneXdAbi}
    if (token === "1sd") fn = {...sharedParams,contractAddress: oneSdContract,abi: oneSdAbi}
    setCurrentJob('Please approve the operation...')
    try {
      const res = await Moralis.executeFunction(fn)
      setCurrentJob('Please wait...')
      const result = await res.wait()
      if (result.status === 1) setAllowance({...allowance, [token === '1sd' ? 'onesd' : token]: amount})
      setCurrentJob('')
    } catch (err) {
      console.log(err)
      setCurrentJob('')
    } finally {
      setCurrentJob('')
    }
  }
  const getPrice = price=> activeCurrency === 'dexo' ? price / dexoUsdRate : price
  return (
      <div className="container">
        <h3 className="st-header">Select an investment package</h3>
        <div className="dexo-switch">
          <div className="token-options">
            {/* <RadioButton checked={activeCurrency === 'usdt'} onRbClick={()=>setActiveCurrency('usdt')}>USDT</RadioButton> */}
            {totalInvestment >= minUsdToStakeDexo ? <RadioButton checked={activeCurrency === 'dexo'} onRbClick={()=>setActiveCurrency('dexo')}>DEXO</RadioButton> : null}
            {totalInvestment < minUsdToStakeDexo ? <div className="low-inv-notice">{`DEXO option is only available if you have more than $${round18(minUsdToStakeDexo).toLocaleString()} investments.`}</div>: ''}
            <RadioButton checked={activeCurrency === '1sd'} onRbClick={()=>setActiveCurrency('1sd')}>1SD</RadioButton>
          </div>
          {/*user && snimda.includes(user.attributes.ethAddress.toLowerCase()) ?
            <VSwitch switchState={devMode} disabled={false} startLabel="Show Test Button" endLabel="" onVsClick={()=>{setDevMode(!devMode)}} /> : null */}
        </div>
        <div className="empty-box mb-3">
          <div className="packages">
            {dynamicPackages.map((pkg,index)=>pkg.active ? <React.Fragment key={'pkg' + pkg.id}>
              {pkg.id === 3 && dynamicPackages.filter(pkg=>pkg.active).length === 4 ? <div className="flex-breaker"></div> : null}
              <Package 
                id={pkg.id}
                title={pkg.title}
                price={getPrice(pkg.price)}
                nominalPrice={pkg.price}
                description1={pkg.description1}
                description2={pkg.description2}
                activeCurrency={activeCurrency}
                stakePending={packagesStatus[index].pending}
                userTokens={userTokens}
                allowance={allowance}
                onApproveToken={gaToken => approveToken(gaToken)}
                onPackageSelect={()=> selectPackage(pkg.price + '000000000000000000', pkg.id)}
                /*onDemoPackageSelect={()=> selectDemoPackage(pkg.id)}
                devMode={devMode}*/
              />
              </React.Fragment> : null)}
          </div>
        </div>
        <div className="white-box mb-3" id="tour_packages_history">
          <h3>Your Investments</h3>
          {packagesHistory.length > 0 ? <table>
            <thead>
              <tr>
                <th>Activation Date</th>
                <th>Amount</th>
                <th>End Date of the Contract</th>
              </tr>
            </thead>
            <tbody>
              {packagesHistory.map((stk,indx)=><tr key={"pkgh"+indx}>
                <td>{moment(stk.date*1000).format('MMM D YYYY')}</td>
                <td>{round18(stk.tokenId === 1 ? stk.dexoAmount : stk.amount).toLocaleString()} {stk.tokenId === 0 ? 'USDT' : stk.tokenId === 1 ? 'DEXO' : '1SD'}</td>
                <td>{stk.isActive ? <button className="sbtn sbtn-disabled">{moment(stk.date*1000).add(25, "month").format('MMM D YYYY')}</button> : null}</td>
              </tr>)}
            </tbody>
          </table> : <div style={{textAlign: 'center'}}>No investments yet</div> }
        </div>
      </div>
  );
}

export default Packages;