import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import {HashRouter, Routes, Route, Navigate} from "react-router-dom";
import "./global.scss";
import "./spinners.css";

import SideMenu from "./components/SideMenu/SideMenu";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Guest from "./pages/Guest";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard/Dashboard";
import RefPanel from "./pages/RefPanel/RefPanel";
import Packages from "./pages/Packages/Packages";
import Announcements from "./pages/Announcements";
import ContactUs from "./pages/Contact/ContactUs";
import Staking from "./pages/Staking";
import TOS from "./pages/tos";
import FAQ from "./pages/faq";
import Help from "./pages/Help";
import Transactions from "./pages/Transactions";

import Admin from "./pages/Admin";
import AdminStats from "./components/Admin/Stats";
import AdminUsers from "./components/Admin/UsersList";
import AdminEditor from "./components/Admin/Editor";
import AdminContract from "./components/Admin/Contract";
import AdminPackages from "./components/Admin/Packages";
import RewardRequests from "./components/Admin/RewardRequests";
import AdminSubmissions from "./components/Admin/Submissions";
import AdminSettings from "./components/Admin/Settings";

import { appId, dexoContract, networkId, oneSdContract, oneXdContract, serverUrl, blockExplorerUrl } from "./constants";
import ProtectedRoute from "./components/ProtectedRoute";
import UsersService from "./services/users.service";

let tmot

function App() {
  const [referral, setReferral] = useState('')
  const [refStatus, setRefStatus] = useState(0)
  const [currentJob, setCurrentJob] = useState('')
  const [flash, setFlash] = useState([])
  const [displayModal, setDisplayModal] = useState(false)
  const [ancCount, setAncCount] = useState(0)
  const [elevated, setElevated] = useState(false)
  const [startTour, setStartTour] = useState(0)
  
  const moralisObj = useMoralis()
  const { authenticate, isWeb3Enabled, enableWeb3, isAuthenticated, Moralis, user, logout } = moralisObj

  useEffect(() => {
    checkRef()
  }, [])

  useEffect(() => {
    if (user) {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", accounts => {
          if (!accounts.includes(user.attributes.ethAddress)) logoutAndRedirect()
        });
      }
    }
  }, [user])

  useEffect(() => {
    clearTimeout(tmot)
    tmot = setTimeout(()=>{
      setFlash([])
    },6000)
  }, [flash])

  function logoutAndRedirect() {
    localStorage.removeItem('wallet_type')
    logout()
    window.location.replace(`/${window.location.search}`);
  }

  async function checkRef () {
    await Moralis.start({appId, serverUrl})
    if (!isWeb3Enabled && localStorage.getItem('wallet_type')) {
      if (localStorage.getItem('wallet_type') === 'trust_wallet') {
        const enblRes = await enableWeb3({ provider: 'walletconnect', chainId: networkId });
        if (enblRes === undefined) localStorage.removeItem('wallet_type')
      } else {
        const enblRes = await enableWeb3();
        if (enblRes === undefined) localStorage.removeItem('wallet_type')
      }
    } 
    let ref = new URLSearchParams(window.location.search).get('ref')
    ref = ref ? ref.toLowerCase() : ''
    const awaitedUser = await Moralis.User.current();
    let refStatus = 'invalid'
    if (awaitedUser) {
      const _refRes = await UsersService.validReferral(awaitedUser.attributes.ethAddress.toLowerCase(), ref)
      refStatus = _refRes.data
      if (refStatus === 'invalid') logoutAndRedirect()
    } else {
      const _refRes = await UsersService.validReferral('', ref)
      refStatus = _refRes.data
    }
    if (refStatus !== 'invalid' || ref === 'alpha') {
      setReferral(ref)
      setRefStatus(1)
    } else {
      setRefStatus(-1)
    }
    getAnnouncementsCount()
  }

  async function getAnnouncementsCount () {
    const query = new Moralis.Query("announcements")
    const res = await query.find()
    setAncCount(res.length)
  }

  async function auth(useWalletConnect) {
    //const enblRes = await enableWeb3();
    //const accounts = await window.ethereum.request({
    //  method: 'eth_requestAccounts'
    //});
    //console.log(accounts)
    try {
      setCurrentJob('Authenticating...')
      if (!isWeb3Enabled) {
        let enblRes
        if (useWalletConnect) {
          enblRes = await enableWeb3({ provider: 'walletconnect', chainId: networkId });
        } else {
          enblRes = await enableWeb3();
        }
        if (enblRes === undefined ) {
          setCurrentJob('')
          return
        }
      }
      const params = useWalletConnect ? { provider: "walletconnect", chainId: networkId } : {}
      await authenticate(params)
      Moralis.User.currentAsync().then(async function(user) {
        if (user) {
          let refStatus = 'invalid'
          const _refRes = await UsersService.validReferral(user.attributes.ethAddress.toLowerCase(), referral.toLowerCase())
          refStatus = _refRes.data
          if (refStatus === 'invalid') logoutAndRedirect()
        }
      });
      localStorage.setItem('wallet_type', useWalletConnect ? 'trust_wallet' : 'metamask')
    } catch (err) {
      console.log('authentication error')
    } finally {
      setCurrentJob('')
    }
  }

  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
    }
  });

  async function addToken(tknId) {
    const options = {
      address: tknId === 'dexo' ? dexoContract : tknId === '1sd' ? oneSdContract : oneXdContract,
      symbol: tknId === 'dexo' ? 'DEXO' : tknId === '1sd' ? '1SD' : '1XD',
      decimals: 18
    }
    try {
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options
        },
      });
      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss');
      }
    } catch (error) {
      console.log(error);
    }
  }

  function addTokensModal() {
    return <React.Fragment>
      <p className="alert">Remember to switch to Binance Smart Chain on your wallet.</p>
    <div>
      <h3>MetaMask</h3>
      <div className="at-btns-wrapper ">
        <button className="sbtn" onClick={()=>{addToken('dexo')}}>Add DEXO</button>
        <button className="sbtn" onClick={()=>{addToken('1xd')}}>Add 1XD</button>
        <button className="sbtn" onClick={()=>{addToken('1sd')}}>Add 1SD</button>
      </div>
    </div>
    <div>
      <h3>TrustWallet</h3>
      <div className="qr-codes">
        <a href={blockExplorerUrl + "/address/" + dexoContract} target="_blank" className="qrcode-wrapper">
          <img src="/qr_dexo.png?2" alt="dexo qr code" />
          <strong>
            <i className="fa-light fa-link"></i>
            DEXO
          </strong>
        </a>
        <a href={blockExplorerUrl + "/address/" + oneXdContract} target="_blank" className="qrcode-wrapper">
          <img src="/qr_onexd.png?2" alt="1xd qr code" />
          <strong>
            <i className="fa-light fa-link"></i>
            1XD
          </strong>
        </a>
        <a href={blockExplorerUrl + "/address/" + oneSdContract} target="_blank" className="qrcode-wrapper">
          <img src="/qr_onesd.png?4" alt="1sd qr code" />
          <strong>
            <i className="fa-light fa-link"></i>
            1SD
          </strong>
        </a>
      </div>
    </div>
    <hr />
    <div className="modal-footer">
      <button className="sbtn sbtn-primary" onClick={()=>setDisplayModal(false)}>Close</button>
    </div>
    </React.Fragment>
  }

  const sharedProps = {moralisObj, setCurrentJob: cj => setCurrentJob(cj), onFlash: fldata=> setFlash([...flash,fldata])}

  return (
    <HashRouter>
    {refStatus === 1 || refStatus === -1 ? 
    <div className="main">
      <div className={`busy-overlay ${currentJob !== '' ? 'active' : ''}`}>
        <div className="spinner"></div>
        <div className="spinner-text">{currentJob}</div>
      </div>
      {displayModal ? <div className='modal'>
        <div className="modal-body">
          {addTokensModal()}
        </div>
      </div> : null}
      {flash.length>0 ? <div className="flash-wrapper" >
        {flash.map((fl,indx)=><div key={'flash'+indx} className={`flash ${fl.type}`} >{fl.message}</div>)}
      </div> : null}
      <div className="content-wrapper">
        <Header
          moralisObj={moralisObj}
          refStatus={refStatus}
          auth={wc => auth(wc)}
          elevated={elevated}
          setElevated={setElevated}
          onDisplayModal={()=>setDisplayModal(true)}
          onStartTour={()=>setStartTour(startTour+1)}
        />
        <SideMenu logout={logoutAndRedirect} activeItem={0} isAuthenticated={isAuthenticated} user={user} ancCount={ancCount} elevated={elevated} onStartTour={startTour} />
        <Routes>
          <Route path="/"               element={<Home          {...sharedProps} />} />
          <Route path="/dashboard"      element={<Dashboard     {...sharedProps} />} />
          <Route path="/packages"       element={<Packages      {...sharedProps} referral={referral.toLowerCase()}/>} />
          <Route path="/transactions"   element={<Transactions  {...sharedProps} />} />
          <Route path="/announcements"  element={<Announcements {...sharedProps} elevated={elevated} ancCount={ancCount} />} />
          <Route path="/admin"          element={<ProtectedRoute elevated={elevated}><Admin {...sharedProps} /></ProtectedRoute>}>
            <Route path="stats"      element={<AdminStats       {...sharedProps} />} />
            <Route path="users"      element={<AdminUsers       {...sharedProps} />} />
            <Route path="editor"     element={<AdminEditor      {...sharedProps} />} />
            <Route path="contract"   element={<AdminContract    {...sharedProps} />} />
            <Route path="packages"   element={<AdminPackages    {...sharedProps} />} />
            <Route path="rewards"    element={<RewardRequests   {...sharedProps} />} />
            <Route path="submissions"element={<AdminSubmissions {...sharedProps} />} />
            <Route path="settings"   element={<AdminSettings    {...sharedProps} />} />
          </Route>
          <Route path="/staking"        element={<Staking       {...sharedProps} elevated={elevated} />} />
          <Route path="/refs"           element={<RefPanel      {...sharedProps} />} />
          <Route path="/tos"            element={<TOS           {...sharedProps} />} />
          <Route path="/faq"            element={<FAQ           {...sharedProps} />} />
          <Route path="/help"           element={<Help                           />} />
          <Route path="/contact-us"     element={<ContactUs     {...sharedProps} />} />
          <Route path="*"               element={<Navigate replace to={`/${window.location.search}`} />} />
        </Routes>
        </div>
        <Footer />
      </div> : <div className="main"><Guest refStatus={refStatus} /></div>}
    </HashRouter>
  );
}

export default App;